import { cloneElement, memo, useMemo } from 'react';
import classNames from 'classnames';

import Link from '@components/atoms/Link';
import Loader from '@components/atoms/Loader';
import Typography from '@components/atoms/Typography';

import classes from './Button.module.scss';
import Props, { Size } from './Button.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Icon = any;

const loaderSizeMap: Record<Size, number> = {
  sm: 18,
  md: 20,
  lg: 23,
};

const Button = ({
  id,
  children,
  disabled = false,
  href,
  icon,
  iconPosition = 'normal',
  loading = false,
  shape = 'circle',
  size = 'md',
  color = 'primary',
  variant = 'contained',
  wide = false,
  openInNewTab = false,
  withoutIconFill = false,
  component,
  trackEvent,
  onClick,
  disabledOnClick,
  type = 'button',
  className: classNameProp,
  classes: customClasses,
  dataEid,
  ...rest
}: Props): JSX.Element => {
  const isRound = shape === 'round';

  const className = classNames(classNameProp, classes.button, classes[variant], classes[color], classes[size], {
    [classes.iconFill]: !withoutIconFill,
    [classes.ripple]: !disabled,
    [classes.disabled]: disabled,
    [classes.wide]: wide,
    [classes.round]: isRound,
    [classes.loading]: loading,
    [classes.onlyIcon]: !children && icon,
    [classes.normal]: iconPosition === 'normal',
  });

  const renderChildren = useMemo(() => {
    if (children) {
      return (
        <Typography
          ellipsisLines={isRound ? 1 : undefined}
          renderAs="span"
          variant={size === 'sm' ? 'body2' : 'body1'}
          className={classNames(classes.textWrapper, customClasses?.content)}
        >
          <div className={classNames(classes.loader, classes.contentInner, { [classes.contentHidden]: !loading })}>
            <Loader isDark={color === 'primary' || ['outlined', 'text'].includes(variant)} size={loaderSizeMap[size]} />
          </div>
          <div className={classNames(classes.contentInner, { [classes.contentHidden]: loading })}>
            {icon && (
              <i
                className={classNames(customClasses?.icon, {
                  [classes.icon]: iconPosition === 'normal',
                  [classes.iconRight]: iconPosition === 'right',
                  [classes.iconLeft]: iconPosition === 'left',
                })}
              >
                {cloneElement(icon as Icon, { width: 15, height: 15 })}
              </i>
            )}
            {children}
          </div>
        </Typography>
      );
    }

    if (!children && icon) {
      return cloneElement(icon as Icon, { width: 18, height: 18 });
    }

    return null;
  }, [children, icon, isRound, size, customClasses, loading, color, variant, iconPosition]);

  return href ? (
    <Link
      href={href}
      role={type}
      className={classNames(className, customClasses?.root)}
      id={id}
      component={component}
      dataEid={dataEid}
      openInNewTab={openInNewTab}
      trackEvent={trackEvent}
    >
      {renderChildren}
    </Link>
  ) : (
    <button
      {...rest}
      id={id}
      data-eid={dataEid}
      // eslint-disable-next-line react/button-has-type
      type={disabled ? 'button' : type}
      className={classNames(className, customClasses?.root)}
      onClick={disabled ? disabledOnClick : onClick}
      disabled={disabledOnClick ? false : disabled}
    >
      {renderChildren}
    </button>
  );
};

export default memo(Button);
